import { Col, Container, Row, Table, Form, Pagination } from "react-bootstrap"
import { Usernav, Userheader, Button } from "../../components"
import neutrel from "../../assets/images/neutrel.png"
import tick from "../../assets/images/tick.png"
import cancel from "../../assets/images/cancel.png"

import { useState } from "react";
import { Link } from "react-router-dom";
import image from "../../assets/images/image.png"







function Setting() {
    const [password, setPassword] = useState(0)


    const [name, setName] = useState("Muhammad Ibtahim")
    const [nameType, setNameType] = useState(0)


    const [email, setEmail] = useState('fazal@gmail.com')
    const [emailType, setEmailType] = useState(0)

    const [contactEmail, setContactEmail] = useState('fazal@gmail.com')
    const [contactEmailType, setContactEmailType] = useState(0)

    
    const [phone, setPhone] = useState("0304040404")
    const [phoneType, setPhoneType] = useState(0)

    const [contactPhone, setContactPhone] = useState("0304040404")
    const [contactPhoneType, setContactPhoneType] = useState(0)

    
    const [address, setAddress] = useState("No 13, sheikh belgore street, BasinIlorin, Kwara State, Nigeria")
    const [addressType, setAddressType] = useState(0)

    const [contactAddress, setContactAddress] = useState("No 13, sheikh belgore street, BasinIlorin, Kwara State, Nigeria")
    const [contactAddressType, setContactAddressType] = useState(0)
    return (

        <>



            <div className="dashboard">

                <Usernav />

                <div className="content">

                    <Userheader name="Opeyemi Pharmacy" />

                    <Container fluid className="main-dashboard">
                        <Row>
                            <Col lg={12}>

                                <div className="white-box mb-4">
                                    <h3>Account Overview</h3>
                                </div>

                                <div className="white-box white-box-flex">

                                    <div className="account-info">

                                        <div className="account-header">

                                            <h4 className="m-0">Name</h4>

                                        </div>

                                        <div className="account-body">
                                            <div className="mb-5">

                                                <div className="edit">

                                                    <input type="text" readOnly={nameType == 0 ? true : false} value={name}
                                                        className={nameType == 0 ? "readonly" : ""} onChange={(e) => setName(e.target.value)} />
                                                    <i class="fa-solid fa-pen" onClick={nameType == 0 ? (e) => setNameType(1) : (e) => setNameType(0)}></i>

                                                </div>

                                                <div className="edit">

                                                    <input type="email" readOnly={emailType == 0 ? true : false} value={email}
                                                        className={emailType == 0 ? "readonly" : ""} onChange={(e) => setEmail(e.target.value)} />

                                                    <i class="fa-solid fa-pen" onClick={emailType == 0 ? (e) => setEmailType(1) : (e) => setEmailType(0)}></i>

                                                </div>

                                                <div className="edit">

                                                    <input type="tel" readOnly={phoneType == 0 ? true : false} value={phone}
                                                        className={phoneType == 0 ? "readonly" : ""} onChange={(e) => setPhone(e.target.value)} />

                                                    <i class="fa-solid fa-pen" onClick={phoneType == 0 ? (e) => setPhoneType(1) : (e) => setPhoneType(0)}></i>

                                                </div>


                                            </div>

                                            <Link to={"#"} className="changepassword" onClick={password == 0 ? (e) => setPassword(1) : (e) => setPassword(0)}>Change Password</Link>

                                            <Form className={password == 0 ? "d-none" : "d-block"}>

                                                <Form.Group className="mb-5" >
                                                    <Form.Label>Enter Old Password</Form.Label>
                                                    <Form.Control type="password" />
                                                </Form.Group>

                                                <Form.Group className="mb-5" >
                                                    <Form.Label>Enter New Password</Form.Label>
                                                    <Form.Control type="password" />
                                                </Form.Group>

                                                <Form.Group className="mb-5" >
                                                    <Form.Label>Confirm New Password</Form.Label>
                                                    <Form.Control type="password" />
                                                </Form.Group>


                                                <input type="submit" variant="primary" value="Save" className="custom-btn primary-btn w-100" />

                                            </Form>


                                        </div>

                                    </div>

                                    <div className="account-info">

                                        <div className="account-header">

                                            <h4 className="m-0">Address</h4>
                                            <i class="fa-solid fa-pen" onClick={addressType == 0 ? (e) => setAddressType(1) : (e) => setAddressType(0)}></i>

                                        </div>

                                        <div className="account-body">

                                            <div className="edit">

                                                {/* <input type="text" readOnly={addressType == 0 ? true : false} value={address} 
                className={addressType == 0 ? "readonly" : ""} onChange={(e) => setAddress(e.target.value)}/> */}

                                                <textarea readOnly={addressType == 0 ? true : false} value={address}
                                                    className={addressType == 0 ? "readonly" : ""} cols="20" onChange={(e) => setAddress(e.target.value)} />


                                            </div>


                                        </div>

                                    </div>

                                </div>
                                <div className="white-box white-box-flex">
                                    <div className="account-info">
                                        <div className="account-header">

                                            <h4 className="m-0">Verification</h4>

                                        </div>

                                        <div className="account-body">

                                            <div className="edit verification">

                                                {/* <input type="text" readOnly={addressType == 0 ? true : false} value={address} 
                                    className={addressType == 0 ? "readonly" : ""} onChange={(e) => setAddress(e.target.value)}/> */}

                                                <div className="inner">

                                                    <div class="upload-btn">
                                                        <Form.Label for="upload-profile" className="upload-label">
                                                            <img src={image} />
                                                            <br />
                                        ID Card Front
                                        </Form.Label>
                                                        <Form.Control type="file" id="upload-profile" />
                                                    </div>



                                                </div>
                                                <div className="inner">

                                                    <div class="upload-btn">
                                                        <Form.Label for="upload-profile" className="upload-label">
                                                            <img src={image} />
                                                            <br />
                                        ID Card Back
                                        </Form.Label>
                                                        <Form.Control type="file" id="upload-profile" />
                                                    </div>



                                                </div>

                                            </div>
                                            <input type="submit" variant="primary" value="Save" className="mt-5 custom-btn primary-btn w-100" />


                                        </div>

                                    </div>
                                    <div className="account-info">

                                        <div className="account-header">

                                            <h4 className="m-0">Verify BVN Number</h4>

                                        </div>

                                        <div className="account-body">

                                            <div className=" w-100">

                                                {/* <input type="text" readOnly={addressType == 0 ? true : false} value={address} 
                                        className={addressType == 0 ? "readonly" : ""} onChange={(e) => setAddress(e.target.value)}/> */}
                                                <div className="position-relative">

                                                    <Form.Control type="text" placeholder="Enter no." id="upload-profile" />
                                                    <input type="submit" variant="primary" value="Verify" className="mt-1 vrify-absolute-btn " />
                                                </div>



                                            </div>


                                        </div>

                                    </div>
                                </div>

                                <div className="white-box white-box-flex">
                                <div className="account-info">
                                        <div className="account-header">

                                            <h4 className="m-0">Licence</h4>

                                        </div>

                                        <div className="account-body">

                                            <div className="edit verification">

                                                {/* <input type="text" readOnly={addressType == 0 ? true : false} value={address} 
                                    className={addressType == 0 ? "readonly" : ""} onChange={(e) => setAddress(e.target.value)}/> */}
                                                <div className="inner m-auto">

                                                    <div class="upload-btn">
                                                        <Form.Label for="upload-profile" className="upload-label">
                                                            <img src={image} />
                                                            <br />
                                        Upload Licence
                                        </Form.Label>
                                                        <Form.Control type="file" id="upload-profile" />
                                                    </div>



                                                </div>

                                            </div>
                                            <input type="submit" variant="primary" value="Save" className="mt-5 custom-btn primary-btn w-100" />


                                        </div>

                                    </div>
                                    <div className="account-info">

                                        <div className="account-header">

                                            <h4 className="m-0">Contact Information</h4>

                                        </div>

                                        <div className="account-body">
                                            <div className="mb-5">

                                                <div className="edit">

                                                    <input type="text" readOnly={contactEmailType == 0 ? true : false} value={contactEmail}
                                                        className={contactEmailType == 0 ? "readonly" : ""} onChange={(e) => setContactEmail(e.target.value)} />
                                                    <i class="fa-solid fa-pen" onClick={contactEmailType == 0 ? (e) => setContactEmailType(1) : (e) => setContactEmailType(0)}></i>

                                                </div>

                                                <div className="edit">

                                                    <input type="email" readOnly={contactPhoneType == 0 ? true : false} value={contactPhone}
                                                        className={contactPhoneType == 0 ? "readonly" : ""} onChange={(e) => setContactPhone(e.target.value)} />

                                                    <i class="fa-solid fa-pen" onClick={contactPhoneType == 0 ? (e) => setContactPhoneType(1) : (e) => setContactPhoneType(0)}></i>

                                                </div>

                                                <div className="edit">

                                                    <input type="tel" readOnly={contactAddressType == 0 ? true : false} value={contactAddress}
                                                        className={contactAddressType == 0 ? "readonly" : ""} onChange={(e) => setPhone(e.target.value)} />

                                                    <i class="fa-solid fa-pen" onClick={contactAddressType == 0 ? (e) => setContactAddressType(1) : (e) => setContactAddressType(0)}></i>

                                                </div>


                                            </div>


                                        </div>

                                    </div>
                                </div>


                            </Col>
                        </Row>
                    </Container>

                </div>

            </div>

        </>

    )

}

export default Setting