import { Col, Container, Row, Table,Form,Pagination  } from "react-bootstrap"
import { Usernav, Userheader, Button } from "../../components"
import neutrel from "../../assets/images/neutrel.png"
import tick from "../../assets/images/tick.png"
import cancel from "../../assets/images/cancel.png"
import tablet1 from "../../assets/images/tablet.png"








function Reviews(){

    return (

        <>

           

           <div className="dashboard">

                <Usernav/>

                <div className="content">

                    <Userheader name="Opeyemi Pharmacy"/>

                    <Container fluid className="main-dashboard">

                      
                      <div className="total-products">

                      <h4>Reviews</h4>


                      </div>
                      <div className="dashboard-filter">



                            <Form>

                            <Row className="justify-content-center">
                                <Form.Group className="mb-5 col-lg-4">
                                    <div className="input-icon">
                                        <Form.Control type="text" placeholder="Search"/>
                                        <i class="fa-solid fa-magnifying-glass icon"></i>
                                    </div>
                                </Form.Group>
                            </Row>
                            
                            </Form>

                            </div>

                      <div className="normal-body">
                      <div className="table-responsive">

                        <table className="cutomer-reviews">

                            <thead>
                                <tr>
                                    <th>
                                    Image & Item Name
                                    </th>
                                    
                                    <th>
                                    Comment
                                    </th>
                                    
                                    <th>
                                    Star & Date
                                    </th>
                                    
                                    
                                </tr>
                                <tr></tr>
                            </thead>

                            <tbody>


                                <tr>
                                    <td>
                                    <img src={tablet1}/>
                                        Paracetamol
                                    </td>

                                    <td>
                                        It was expire.
                                    </td>

                                    <td>

                                    <div className="rating">
                                        <i class="fa-solid fa-star"></i>
                                        <i class="fa-solid fa-star"></i>
                                        <i class="fa-solid fa-star"></i>
                                        <i class="fa-solid fa-star"></i>
                                        <i class="fa-solid fa-star-half-stroke"></i>
                                    </div>

                                    <p className="date-sm">20/10/2020</p>

                                    </td>

                                </tr>

                                <tr>
                                    <td>
                                    <img src={tablet1}/>
                                        Paracetamol
                                    </td>

                                    <td>
                                        It was expire.
                                    </td>

                                    <td>

                                    <div className="rating">
                                        <i class="fa-solid fa-star"></i>
                                        <i class="fa-solid fa-star"></i>
                                        <i class="fa-solid fa-star"></i>
                                        <i class="fa-solid fa-star"></i>
                                        <i class="fa-solid fa-star-half-stroke"></i>
                                    </div>

                                    <p className="date-sm">20/10/2020</p>

                                    </td>

                                </tr>

                                

                            </tbody>

                        </table>
                        </div>

                      </div>


                    </Container>

                </div>
        
            </div>
        
        </>

    )

}

export default Reviews