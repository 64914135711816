import { Modal } from "react-bootstrap";
import neutrel from "../../assets/images/neutrel.png"
import tick from "../../assets/images/tick.png"
import cancel from "../../assets/images/cancel.png"
function Order(props) {

  
    return (
      <>
        <Modal show={props.show} onHide={props.handleClose} size="lg" aria-labelledby="example-modal-sizes-title-lg">
          <Modal.Header closeButton>
            <Modal.Title className="market-name">Invoice : #000111</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <div className="d-flex ">
              <div>
              <h5>Delivery Information</h5>
              <h6>Customer : Hydraxx</h6>
              <h6>Time : 20/20/2020 10:08PM</h6>
              <h6>Address : No 16, Belgore str Ilorin, Kwara St</h6>
              </div>
              <div className="ms-5">
              <h5>Price Summary</h5>
              <h6>Subtotal : N5</h6>
              <h6>Grandtotal : N15</h6>
              </div>
              </div>
              <div className="normal-body order-detail">
              <div className="table-responsive">

            <table className="doctor-products">

                <thead>

                    <tr>
                        <th>Item Name</th>

                        <th>Price</th>
                        <th>Qty</th>
                        <th>Total</th>
                        <th></th>
                        
                    </tr>

                    <tr></tr>

                </thead>

                <tbody>


                    <tr>

                        <td>Boska 4 Tables</td>

                        <td>N5</td>
                        <td>1</td>
                        <td>N5</td>


                        <td></td>

                        <td><img src={tick}/></td>

                    </tr>
                    <tr>

                    <td>Boska 4 Tables</td>

                    <td>N5</td>
                    <td>1</td>
                    <td>N5</td>


                    <td></td>

                    <td><img src={tick}/></td>

                    </tr>
                    <tr>

                    <td>Boska 4 Tables</td>

                    <td>N5</td>
                    <td>1</td>
                    <td>N5</td>


                    <td></td>

                    <td><img src={tick}/></td>

                    </tr>


                    

                </tbody>


                </table>

            </div>
            </div>
          </Modal.Body>
          {/* <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button>
          </Modal.Footer> */}
        </Modal>
      </>
    );
  }
  
export default Order