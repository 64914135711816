import user from "../../assets/images/user.png"
import bell from "../../assets/images/bell.png"
import notification from "../../assets/images/notification.png"
import chat from "../../assets/images/messenger.png"
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import products from "../../assets/images/products.png"
import { useState } from "react";


function Userheader(props){

    const onClick = (e) => {
        e.preventDefault()
      }
    const [userType,setUserType] = useState(localStorage.getItem("user_type"))
    return(

        <>

            <div className="dashboard-header">

                <div>
                    <h4 className="market-name">{userType == 'doctor' && 'Doctor' ||
                     userType == 'wholesaler' && 'Wholesaler' || userType == 'lab' && 'Lab'
                     || userType == 'pharmacy' && 'Pharmacy'
                     || userType == 'retailer' && 'Retailer'}</h4>
                    <h4>Dashboard</h4>
                </div>

                <div className="d-flex">
                    <Link to={"/dashboard/setting"}>
                    <img src={user} className="user-avatar mx-4"/>

                    </Link>
                    <div className="">
                    <div class="dropdown">
                    <Link to onClick={onClick} role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                    <span className="noti-icons">
                            <img src={bell}  width="30"/>
                            <span className="yellow">5</span>
                    </span>
                        </Link>
                    <ul class="dropdown-menu chart-menu-dropdown" aria-labelledby="dropdownMenuLink">
                    <div className="chat-body">

                        <div className="li">
                        <span className="noti">
                            <img src={user}/>
                            <div>
                            <p>Successfully Added a new product</p>
                            <div className="text-end">
                            <p>20/08/2020  8:04pm</p>
                            </div>
                            </div>
                        </span>

                        <span className="date">
                        </span>
                        </div>
                        <div  className="li">
                        <span className="noti">
                            <img src={user}/>
                            <div>
                            <p>Successfully Added a new product</p>
                            <div className="text-end">
                            <p>20/08/2020  8:04pm</p>
                            </div>
                            </div>
                        </span>

                        <span className="date">
                        </span>
                        </div>
                        <div  className="li">
                        <span className="noti">
                            <img src={user}/>
                            <div>
                            <p>Successfully Added a new product</p>
                            <div className="text-end">
                            <p>20/08/2020  8:04pm</p>
                            </div>
                            </div>
                        </span>

                        <span className="date">
                        </span>
                        </div>

                    </div>

                    </ul>
                    </div>
                        
                    </div >

                        <Link to={"/dashboard/chat"} >
                            <span className="noti-icons">
                                <img src={chat} width="30"/>
                                <span className="purple">3</span>
                            </span>
                        </Link>

                </div>

            </div>

        </>

    )

}

export default Userheader