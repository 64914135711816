import { Col, Container, Row, Table,Form,Pagination  } from "react-bootstrap"
import { Usernav, Userheader, Button } from "../../components"
import neutrel from "../../assets/images/neutrel.png"
import tick from "../../assets/images/tick.png"
import cancel from "../../assets/images/cancel.png"
import OrderModal from '../../components/Dashboard/Order'
import { useState } from "react"
import { Link } from "react-router-dom"







function Chat(){

    return (

        <>

           

           <div className="dashboard">

                <Usernav/>

                <div className="content">

                    <Userheader name="Opeyemi Pharmacy"/>

                    <Container fluid className="main-dashboard">

                      
                      <div className="total-products">

                      <h4>Chat</h4>


                      </div>
                      <div className="">
                        <div className="chat-main">
                            <div className="chat-side">
                                <div className="chart-header">
                                    <div className="position-relative">
                                    <input className="form-control" placeholder="search"/>
                                    <i class="fa-solid fa-magnifying-glass search-icon"></i>
                                    </div>
                                </div>
                                <ul>
                                    <li className="active">
                                        <img src={cancel}/>
                                        <div className="w-100">
                                        <div className="flex">
                                        <span className="first">Test</span>
                                        <span className="second">4:04 AM</span>
                                        </div>
                                        <span className="second">how are you</span>
                                        </div>
                                    </li>
                                    <li>
                                        <img src={cancel}/>
                                        <div className="w-100">
                                        <div className="flex">
                                        <span className="first">Test</span>
                                        <span className="second">4:04 AM</span>
                                        </div>
                                        <span className="second">how are you</span>
                                        </div>
                                    </li>
                                    <li>
                                        <img src={cancel}/>
                                        <div className="w-100">
                                        <div className="flex">
                                        <span className="first">Test</span>
                                        <span className="second">4:04 AM</span>
                                        </div>
                                        <div className="btn-section-flex">
                                            <button className="custom-small-btn accept-btn">Accept</button>
                                            <button className="custom-small-btn cancel-btn">Reject</button>
                                        </div>
                                        </div>
                                    </li>
                                    <li>
                                        <img src={cancel}/>
                                        <div className="w-100">
                                        <div className="flex">
                                        <span className="first">Test</span>
                                        <span className="second">4:04 AM</span>
                                        </div>
                                        <span className="second">how are you</span>
                                        </div>
                                    </li>
                                    <li>
                                        <img src={cancel}/>
                                        <div className="w-100">
                                        <div className="flex">
                                        <span className="first">Test</span>
                                        <span className="second">4:04 AM</span>
                                        </div>
                                        <span className="second">how are you</span>
                                        </div>
                                    </li>
                                    <li>
                                        <img src={cancel}/>
                                        <div className="w-100">
                                        <div className="flex">
                                        <span className="first">Test</span>
                                        <span className="second">4:04 AM</span>
                                        </div>
                                        <span className="second">how are you</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="chart-body">
                                <div className="chart-header">
                                        <img src={cancel}/>
                                        <div className="w-100">
                                        <div className="flex">
                                        <span className="first">Test</span>
                                        </div>
                                        <span className="second">last seen 1h ago</span>
                                        </div>
                                </div>
                                <div className="chat-section">
                                    <div className="chat-text-box text-end">
                                        <span className="background-primary">hi how are you  <sub> 4:00pm</sub></span>
                                    </div>
                                    <div className="chat-text-box ">
                                        <span className="background-gray">hi how are you  <sub> 4:00pm</sub></span>
                                    </div>
                                    <div className="chat-text-box text-end">
                                        <span className="background-primary">hi how are you  <sub> 4:00pm</sub></span>
                                    </div>
                                    <div className="chat-text-box ">
                                        <span className="background-gray">hi how are you  <sub> 4:00pm</sub></span>
                                    </div>
                                    <div className="chat-text-box text-end">
                                        <span className="background-primary">hi how are you  <sub> 4:00pm</sub></span>
                                    </div>
                                    <div className="chat-text-box ">
                                        <span className="background-gray">hi how are you  <sub> 4:00pm</sub></span>
                                    </div>
                                    <div className="chat-text-box text-end">
                                        <span className="background-primary">hi how are you  <sub> 4:00pm</sub></span>
                                    </div>
                                    <div className="chat-text-box ">
                                        <span className="background-gray">hi how are you  <sub> 4:00pm</sub></span>
                                    </div>
                                    <div className="chat-text-box text-end">
                                        <span className="background-primary">hi how are you  <sub> 4:00pm</sub></span>
                                    </div>
                                    <div className="chat-text-box ">
                                        <span className="background-gray">hi how are you  <sub> 4:00pm</sub></span>
                                    </div>
                                    <div className="footer-chat">
                                        <div className="position-relative">
                                        <input className="form-control" placeholder="type here"/>
                                        <i class="fa-solid fa-paper-plane send-btn"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>


                    </Container>

                </div>
        
            </div>
        
        </>

    )

}

export default Chat